'use client';

import { PropsWithChildren } from 'react';

import { sendLog } from '@/services/logs';

import styles from './styles.module.scss';

interface Props extends PropsWithChildren {
  anchorId: string;
}

export default function ScrollIntoGroupButton({ anchorId, children }: Props) {
  return (
    <button
      className={styles.button}
      type="button"
      onClick={() => {
        sendLog('ui_homepage_banner_productTapped', {
          id: anchorId,
        });
        const el = document.getElementById(anchorId);
        if (!el) return;
        document.documentElement.scroll({
          top: el.offsetTop + 30,
          behavior: 'smooth',
        });
      }}
    >
      {children}
    </button>
  );
}
